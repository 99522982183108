.discount-rules-container {
  padding: 20px;
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
}
.heading {
  margin-top: 2rem;
  font-weight: 700;
}
.discount-rules-container h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 24px;
  color: #333;
}

.deal-cards {
  display: flex;
  justify-content: center;
  height: 50vh;
  gap: 5rem;
  flex-wrap: wrap;
  margin-top: 5rem;
}

.deal-card {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 15rem;
}

.deal-icon {
  margin-bottom: 15px;
  color: #007bff;
}

.deal-card h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #007bff;
}

.deal-card p {
  font-size: 14px;
  color: #555;
  margin: 5px 0;
}

.add-promotion-btn {
  background: #007bffd6;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 15px;
  width: 100%;
  transition: ease-in-out 0.2s;
}

.add-promotion-btn:hover {
  transition: ease-in 0.2ms;
  background: #007bff;
}

/*  deals  layout  */
.deal-dialog-container{
    height: 100vh;
    width: 100vw;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}
.basic-inputs-container {
  height: 90vh;
  width: 40vw;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 100;
  top: 2rem;
  border-radius: 0.5rem;
  padding: 2rem;

}

.basic-inputs-container::-webkit-scrollbar{
display: block;
visibility: hidden;
}


.overlay{
    z-index: -2;
    height: 100vh;
    width: 100vw;
    opacity: 0.4;
    background-color: black;
    position: fixed;
    inset: 0;
}