.filter-select {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 2rem;
  border-radius: 20px;
}
.filter-select >select {
    padding: 0.3rem 0.6rem;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
}
.main-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
