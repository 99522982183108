.days-row {
  display: flex;
  min-width: max-content;
  align-items: center;
}

.inventory-row {
  display: flex;
  align-items: center;
}

.field-name {
  width: 150px; /* Adjust width as necessary */
  min-width: 150px;
  background-color: white;
  position: sticky;
  left: 0;
  z-index: 100;
  padding: 5px;
  border: 1px solid #ccc;
  /* box-shadow: 2px 0 5px rgba(0,0,0,0.1); */
  /* border-right: 2px solid #e0e0e0; */
}



.inventory-input {
  flex: 0 0 40px;
  width: 40px;
  text-align: center;
  margin: 0;
  padding: 5px 0;
  border: 1px solid #e0e0e0;
  border-right: none;
}

.inventory-input:last-child {
  border-right: 1px solid #e0e0e0;
}

.save-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #2c3e50;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: medium;
  font-weight: 700;
  margin-bottom: 1rem;
}



/* ficing at top*/

.inventory-container {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 0;
  /* padding: 10px 0;  */
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);  */
}

.calendar-header, .inventory-row {
  display: flex;
  min-width: max-content;
}

.calendar-day, .inventory-input {
  flex: 1 0 80px;
  min-width: 80px;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
}

.upload-box {
  background-color: var(--secondary);
  border-radius: 0.5rem;
  padding: 4rem 6rem;
  text-align: center;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--text);
  margin: 2rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .inventory-controls {
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
  }

  .inventory-controls > div:first-child {
      margin-left: 0;
  }

  .inventory-controls input[type="text"] {
      width: 100%;
      margin-right: 0;
  }

  .top-buttons {
      margin-right: 0;
      justify-content: space-between;
  }
}

@media (max-width: 480px) {
  .top-buttons {
    display: flex;
    flex-direction: row;
    align-items: center; /* Centers the buttons horizontally */
    justify-content: center; /* Centers the buttons vertically */
    width: 100%;
    margin: 0 auto; /* Center the .top-buttons div itself if necessary */
}

  .top-buttons button {
      width: 80%;
      margin: 10px;
  }
}

/* Small screens, laptops (769px — 1024px) */
@media screen and (min-width: 758px) and (max-width: 1024px) {

    .inventory-controls {
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
    }
    
  .inventory-controls > div:first-child {
    margin-left: 0;
}

.inventory-controls input[type="text"] {
    width: 80%;
    margin-right: 0;
}

.top-buttons {
    margin-right: 0;
    justify-content: space-between;
}
  
}