.basic-deal-container {
  width: 400px;
  margin: auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  overflow-y: auto;
}

.basic-inputs-container {
  max-width: 600px;
  margin: auto;
  padding: 20px ;
  border-radius: 8px;
  border: 1px solid #ccc;
  background: #e7e6e6;
  overflow-y: auto;
}



.sub-heading {
  font-size: 18px;
  display: flex;
  align-items: start;
  font-weight: 600;
}
.sub-heading-h3 {
  font-size: 16px;
  display: flex;
  align-items: start;
  font-weight: 600;
}

p {
  font-size: 14px;
  color: #555;
}

.promotion-details {
  margin-top: 20px;
  background-color: rgb(255, 255, 255);
  padding: 1rem;
  border-radius: 10px;
}

.radio-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.radio-group label {
  margin-bottom: 5px;
}

input[type="number"] {
  width: 50px;
  padding: 5px;
  margin-top: 5px;
}

.actions {
  margin-top: 20px;
  display: flex;
  justify-content: start;
  gap: 1.5rem;
}

.cancel-btn {
  background: #d9534f;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
}

.cancel-btn:hover {
  background: #c9302c;
}

.promotion-details-radio{
    display: flex;
    flex-direction: column  ;
    gap: 0.3rem;
}
.blocks{
  border-bottom: 2px solid rgba(76, 76, 76, 0.251);
  margin-bottom: 2rem;
  padding-bottom: 1rem;
}

input[type="date"] {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.summary-box {
  border: 1px solid #a5c8ff;
  background: #eef6ff;
  padding: 10px;
  border-radius: 5px;
}

.blocks {
  margin-top: 15px;
}

.sub-heading-h3 {
  font-size: 18px;
  margin-bottom: 10px;
}
.single-radio-div{
  display: flex;
  gap: 0.5rem;
}
.dialog-heading{
  font-weight: 700;
  font-size: 18px;
  
  color: gray !important;

}
.activate-btn {
  background: #4f6bd9;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
}

.activate-btn:hover {
  background: #425fd4;
}

.close-icon{
  position: absolute;
  top: 13px;
  right: -2px;
  cursor: pointer;

}